<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="none"
            :comboItems="plan.depts"
            itemText="deptName"
            itemValue="deptCd"
            name="deptCd"
            label="평가부서"
            v-model="deptCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="평가결과표"
          :columns="grid.columns"
          :data="tableFiltering"
          :gridHeight="gridHeight"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <apexchart 
          ref="chart" 
          :height="gridHeight" 
          type="bar"
          :width="chart.chartWidth"
          :options="chart.chartOptions" 
          :series="[{
            name: '구분',
            data: chartFiltering
          }]"></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'jobStressAssessmentResultStatus',
  components: { 
    apexchart: VueApexCharts
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',
      }),
    },
    plan: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        plantCd: null,  // 사업장
        planYear: '',  // 년도
        jobStressPlanName: '',  // 평가계획명
        assessmentStartDate: '',  // 평가 시작일
        assessmentEndDate: '',  // 평가 종료일
        period: [], // 평가기간
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        users: [], // 평가자
        centers: [], // 중앙값
        depts: [], // 중앙값
        imprs: [], // 중앙값
        resultTable: [],
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      deptCd: null,
      grid: {
        merge: [
          { index: 0, colName: 'heaStressCheckClassName', }
        ],
        columns: [
          {
            name: 'heaStressCheckClassName',
            field: 'heaStressCheckClassName',
            label: '구분',
            align: 'center',
            sortable: false,
            style: 'width: 120px',
          },
          {
            name: 'score',
            field: 'score',
            label: '점수',
            align: 'right',
            sortable: false,
            style: 'width: 50px',
          },
          {
            name: 'c',
            field: 'c',
            label: '회사중앙값',
            child: [
              {
                name: 'companyMale',
                field: 'companyMale',
                label: '남성',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
              {
                name: 'companyFemale',
                field: 'companyFemale',
                label: '여성',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
              {
                name: 'company',
                field: 'company',
                label: '전체',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
            ]
          },
          {
            name: 'k',
            field: 'k',
            label: '한국 근로자 중앙값',
            child: [
              {
                name: 'koreaMale',
                field: 'koreaMale',
                label: '남성',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
              {
                name: 'koreaFemale',
                field: 'koreaFemale',
                label: '여성',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
              {
                name: 'korea',
                field: 'korea',
                label: '전체',
                align: 'right',
                sortable: false,
                style: 'width: 50px',
              },
            ]
          },
        ],
      },
      chart: {
        series: [
          {
            name: '구분',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              columnWidth: '60%'
            }
          },
          colors: ['#4CAF50'],
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['구분', '회사 중앙값(전체)'],
            markers: {
              fillColors: ['#4CAF50', '#607D8B']
            }
          }
        },
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight && !isNaN(this.contentHeight) ? String(this.contentHeight - 200) + 'px' : '500px'
    },
    tableFiltering() {
      let items = [];
      if (this.deptCd) {
        items = this.$_.filter(this.plan.resultTable, { deptCd: this.deptCd })
      }
      return items;
    },
    chartFiltering() {
      let items = [];
      if (this.deptCd) {
        let _filter = this.$_.filter(this.plan.resultTable, { deptCd: this.deptCd })
        this.$_.forEach(_filter, item => {
          items.push({
            x: item.heaStressCheckClassName,
            y: item.score,
            goals: [
              {
                name: '회사 중앙값(전체)',
                value: item.company,
                strokeHeight: 5,
                strokeColor: '#607D8B'
              },
              // {
              //   name: '회사 중앙값(남)',
              //   value: item.companyMale,
              //   strokeHeight: 5,
              //   strokeColor: '#607D8B'
              // },
              // {
              //   name: '회사 중앙값(여)',
              //   value: item.companyFemale,
              //   strokeHeight: 5,
              //   strokeColor: '#607D8B'
              // },
            ]
          })
        })
      }
      return items;
    },
  },
  watch: {
    'plan.depts': {
      handler: function () {
        let _deptCd = this.plan.depts && this.plan.depts.length > 0 ? this.plan.depts[0].deptCd : null
        this.$set(this.$data, 'deptCd', _deptCd)
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      let _deptCd = this.plan.depts && this.plan.depts.length > 0 ? this.plan.depts[0].deptCd : null
      this.$set(this.$data, 'deptCd', _deptCd)
    },
  }
};
</script>